// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
export default function Error() {
  return (
    <>
      <Layout>
        <h1>Lo siento no se pudo enviar tu mensaje intentalo mas tarde</h1>
      </Layout>
    </>
  )
}
